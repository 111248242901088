export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Listen to the good news",
  headline: "Eternal Life ... (with no transaction fees)",
  description:
    "Get access to our exclusive app that helps you to bind to things eternal",
  buttonLabel: "Get Started",
  imgStart: false,
  img: require("../../images/cross.png"),
  alt: "Crosses",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Unlimited Access to Heaven",
  headline: "Enter through this Gateway when you are ready",
  description:
    "I have you covered no matter where you are located. All you need to do is ask",
  buttonLabel: "Listen More",
  imgStart: true,
  img: require("../../images/jesus.png"),
  alt: "Jesus",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "signup",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Join our Team",
  headline: "Getting started is a snap!",
  description:
    "Start praying to be close to him, accept Christ, accept eternal life.",
  buttonLabel: "Start Now",
  imgStart: false,
  img: require("../../images/JesusCallingU.png"),
  alt: "Papers",
  dark: false,
  primary: false,
  darkText: true,
};
